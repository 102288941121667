import React, { ReactNode, useMemo, useState } from 'react';
import { PageSection, Image, Swiper, SwiperItem, Container, Button } from '@/components/_root';
import Reaction from '@/components/_root/card/reaction';
import { useViewport } from '@/components/_hook';
import { Filter } from '@/components//latest-blogs-section/styled';

import { ICaseStudy } from '@/utils/types';
import { getCarouselItemDimension } from '@/utils/carousel';
import getGradientSteps from '@/utils/helper/getGradientSteps';
import { IFilter } from '@/utils/interface';

import * as styles from '@/components/latest-blogs-section/style.module.css';
import { Link } from 'gatsby';
import SectionTitle from '../section-title';

const CAROUSEL_ITEM_MIN_HEIGHT = 374;
const CAROUSEL_ITEM_MAX_HEIGHT = 438;
const SUMMARY_CHAR_LIMIT = 100;

interface Props {
  title: {
    solid?: string;
    outlined?: string;
    isOutlinedFirst?: boolean;
    isInline?: boolean;
    className?: string;
  };
  filterData?: IFilter[];
  headerClass?: string;
  isDark?: boolean;
  isGradientText?: boolean;
  caseStudies: ICaseStudy[];
  reactionStats?: {
    like: string;
    celebrate: string;
    love: string;
  };
}

interface SwiperProps {
  caseStudies: ICaseStudy[];
  itemHeight: number;
  isGradientText?: boolean;
  reactionStats?: {
    like: string;
    celebrate: string;
    love: string;
  };
  selectedCategory?: string;
  showNavigation: boolean;
}

const colors: string[] = getGradientSteps(['#9542F6', '#EA36A8', '#F09A52'], 5);
const showNavigation: boolean = true;

const SEOExperts = ({
  title,
  headerClass,
  isDark = true,
  isGradientText = true,
  caseStudies,
  filterData,
  reactionStats,
}: Props): JSX.Element => {
  const { container } = useViewport();

  const itemHeight = useMemo(
    () => getCarouselItemDimension(CAROUSEL_ITEM_MIN_HEIGHT, CAROUSEL_ITEM_MAX_HEIGHT, container),
    [container],
  );
  const [selectedCategory, setSelectedCategory] = useState('');

  const SPACE_BETWEEN = {
    sm: 0,
    md: 10,
    lg: 32,
    xl: 40,
  };

  const SHOW_ITEMS = {
    sm: 2,
    md: 3,
    lg: 4,
    xl: 6,
  };

  return (
    <PageSection mode={isDark ? 'dark' : 'light'} className={isDark ? `text-white` : ``}>
      <Container>
        <div
          className={`pb-8 md:pb-20 flex ${title.isInline ? 'flex-row gap-4' : 'flex-col'
            } ${headerClass}`}
        >
          <SectionTitle title={title} mode={isDark ? 'dark' : 'light'} />
        </div>
        {filterData && (
          <Filter>
            <Button
              shape="round"
              size="small"
              type="secondary"
              mode={isDark ? 'dark' : 'light'}
              className={`${styles.buttonWidth} ${selectedCategory === '' && styles.active + ' bg-dominant'
                }`}
              onClick={(): void => setSelectedCategory('')}
            >
              All
            </Button>
            <div className="flex gap-x-2 overflow-x-scroll" style={{
              marginLeft: 10
            }}>
              {filterData.map(({ node }, index) => (
                <Button
                  key={node.id}
                  shape="round"
                  size="small"
                  type="secondary"
                  mode={isDark ? 'dark' : 'light'}
                  className={`${styles.buttonWidth}  ${selectedCategory === node.title && styles.active + ' bg-dominant'
                    }`}
                  onClick={(): void => setSelectedCategory(node.title)}
                >
                  {node.title}
                </Button>
              ))}
            </div>
            {/* <Swiper spaceBetween={SPACE_BETWEEN} showItems={SHOW_ITEMS}>
              {filterData.map(({ node }, index) => (
                <SwiperItem key={node.id} className="w-auto">
                  <div className={`w-min flex`}>
                    <Button
                      key={node.id}
                      shape="round"
                      size="small"
                      type="secondary"
                      mode={isDark ? 'dark' : 'light'}
                      className={`${styles.buttonWidth}  ${selectedCategory === node.title && styles.active + ' bg-dominant'
                        }`}
                      onClick={(): void => setSelectedCategory(node.title)}
                    >
                      {node.title}
                    </Button>
                  </div>
                </SwiperItem>
              ))}
            </Swiper> */}
          </Filter>
        )}
      </Container>
      <SEOExperts.Swiper
        caseStudies={caseStudies}
        itemHeight={itemHeight}
        isGradientText={isGradientText}
        reactionStats={reactionStats}
        selectedCategory={selectedCategory}
        showNavigation={showNavigation}
      />
    </PageSection>
  );
};

SEOExperts.LinkOrDiv = ({
  withDetails,
  title,
  children,
}: {
  withDetails: boolean | undefined;
  title: string;
  children: ReactNode;
}): JSX.Element => {
  if (withDetails) {
    return <Link to={`/team/${title.toLowerCase().replace(/ /g, '-')}`}>{children}</Link>;
  }
  return <div>{children}</div>;
};

SEOExperts.Swiper = ({
  caseStudies,
  itemHeight,
  isGradientText,
  reactionStats,
  selectedCategory,
}: SwiperProps): JSX.Element => {
  return (
    <Swiper haveOffset showBleeding showNavigation isLoop={false} isCenter>
      {caseStudies?.map(
        (
          {
            thumbLink,
            subtitle,
            title,
            summary,
            category,
            should_show_details,
            is_current_member,
          }: ICaseStudy,
          index: number,
        ) => {
          if (!is_current_member) return null;
          if (selectedCategory && category !== selectedCategory) return null;
          return (
            <SwiperItem key={index}>
              <SEOExperts.LinkOrDiv withDetails={should_show_details} title={title}>
                <Image
                  src={thumbLink}
                  alt={title}
                  className={`rounded object-cover mb-8 w-full overflow-hidden`}
                  style={{ height: itemHeight }}
                />
                <h5 className={`pb-2`}>{subtitle}</h5>
                <h3
                  className={`truncate`}
                  style={{
                    background: `-webkit-linear-gradient(left,${isGradientText ? colors.join() : '#333'
                      })`,
                    WebkitTextFillColor: `${isGradientText ? 'transparent' : '#333'}`,
                    WebkitBackgroundClip: 'text',
                  }}
                >
                  {title}
                </h3>
                <p className={`mt-4 normal-case`}>
                  {summary?.length > SUMMARY_CHAR_LIMIT
                    ? `${summary.substring(0, SUMMARY_CHAR_LIMIT)} ...`
                    : summary}
                </p>
                {reactionStats && (
                  <div className="mt-8">
                    <Reaction size="small" reactionStats={reactionStats} />
                  </div>
                )}
              </SEOExperts.LinkOrDiv>
            </SwiperItem>
          );
        },
      )}
    </Swiper>
  );
};

export default SEOExperts;
