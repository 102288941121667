import React from 'react';
import { Container, HeaderText } from '@/components/_root';
import { AddressItem, AddressWrapper, OverlayContainer, HeaderWrapper } from './styled';

interface IAddress {
  address: string;
  address_short_text: string;
  direction: string;
}
interface Props {
  backgroundImageUrl: string;
  title: { solid?: string; outlined?: string };
  isAddress?: boolean;
  addresses?: IAddress[];
  isOverlay?: boolean;
  headerTextWidth?: string;
}

const PageBanner = ({
  backgroundImageUrl,
  title,
  isAddress = true,
  addresses,
  isOverlay = true,
  headerTextWidth
}: Props): JSX.Element => {
  return (
    <OverlayContainer backgroundImage={backgroundImageUrl} isOverlay={isOverlay}>
      <Container>
        <HeaderWrapper isAddress={isAddress} headerTextWidth={headerTextWidth}>
          <HeaderText size="h1" mode="dark">
            {title.solid}
          </HeaderText>
        </HeaderWrapper>
        {isAddress && (
          <AddressWrapper>
            {addresses &&
              addresses.map((address: IAddress, index) => (
                <AddressItem key={index} address={address} />
              ))}
          </AddressWrapper>
        )}
      </Container>
    </OverlayContainer>
  );
};

export default PageBanner;
