import React, { ReactNode } from 'react';
import { Container, PageSection, SwiperItem } from '@/components/_root';
import { FounderItem, HeaderWrapper } from './styled';
import Link from 'gatsby-link';
import CarouselOrGrid from '@/components/carousel-or-grid';
import SectionTitle from '../section-title';

interface Props {
  title: { outlined?: string; solid?: string };
  children?: ReactNode;
  size?: 'large' | 'small';
  className?: string;
  founders: {
    category?: string;
    description: string;
    name: string;
    position: string;
    photo: any;
    should_show_details?: boolean;
  }[];
}
const LinkOrDiv = ({
  withDetails,
  name,
  children,
}: {
  withDetails: boolean | undefined;
  name: string;
  children: ReactNode;
}): JSX.Element => {
  if (withDetails) {
    return <Link to={`/team/${name.toLowerCase().replace(/ /g, '-')}`}>{children}</Link>;
  }
  return <div>{children}</div>;
};

const CofoundersPreview = ({ title, className = '', founders }: Props): JSX.Element => {
  return (
    <PageSection mode="dark">
      <Container>
        <HeaderWrapper>
          <SectionTitle title={title} mode="dark" isInline />
        </HeaderWrapper>
      </Container>

      <CarouselOrGrid gridItemCount={2}>
        {founders?.map((founder, index) => (
          <SwiperItem key={index}>
            <LinkOrDiv withDetails={founder?.should_show_details} name={founder?.name}>
              <FounderItem
                bannerLink={founder.photo}
                bannerAlt=""
                subHeading={founder.position}
                heading={founder.name}
                description={founder.description}
                className={className}
              />
            </LinkOrDiv>
          </SwiperItem>
        ))}
      </CarouselOrGrid>
    </PageSection>
  );
};

export default CofoundersPreview;
