import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';

import PageBanner from '@/components/teams-page-banner';
import TeamsHighlights from '@/components/teams-highlights-section';
import CofoundersPreview from '@/components/cofounders-preview-section';
import Riser from '@/components/riser-section';
import BrandSection from '@/components/brand-section';
import GlobalMap from '@/components/map';
import TeamMemberSlider from '@/components/seo-experts-slider';
import SectionSummary from '@/components/section-summary';
import AllStartedSection from '@/components/all-started-section';

import { getTitle } from '@/utils/helper/getTitle';

import { graphql, PageProps } from 'gatsby';
import formatServiceExperts from '@/utils/helper/format-service-experts';
// import formatExpertFilter from '@/utils/helper/format-expert-filter';
import { filterData } from '@/utils/dummy-data/latest-blogs-data';

const TeamsPage = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbLabel="Team" crumbs={crumbs}>
      <Seo
        title={data?.sanityTeamsPageMetadata?.teams_page_metadata?.pageMetaName || 'Team'}
        description={data?.sanityTeamsPageMetadata?.teams_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityTeamsPageMetadata?.teams_page_metadata?.metadata}
        canonical={data?.sanityTeamsPageMetadata?.teams_page_metadata?.canonical}
      />
      <PageBanner
        backgroundImageUrl={data?.sanityTeamsBanner?.background_image}
        title={{ solid: data?.sanityTeamsBanner?.title }}
        addresses={data?.sanityTeamsBanner?.addresses}
      />
      <BrandSection.GradientBackground
        title={{ solid: data?.sanityTeamsFeatured?.title, outlined: '' }}
        backgroundImgUrl={data?.RISER_IMAGE}
        brandLogoUrls={data?.sanityTeamsFeatured?.teams_featured}
      />
      <SectionSummary
        imageUrl={data?.sanityTeamsSummary?.image}
        title={getTitle(data?.sanityTeamsSummary)}
        description={data?.sanityTeamsSummary._rawDescription}
      />
      <AllStartedSection
        title={getTitle(data?.sanityTeamsAllStarted)}
        description={data?.sanityTeamsAllStarted?._rawDescription}
        imageUrl={data?.sanityTeamsAllStarted?.banner_image}
        notificationUrl={data?.sanityTeamsAllStarted?.notification_image?.asset?.url}
      />
      <div className="w-full bg-white  relative z-10 -mt-24 md:-mt-0">
        <TeamsHighlights
          title={getTitle(data?.sanityTeamsWeAreAt)}
          highlights={data?.sanityTeamsWeAreAt}
          brandLogoUrls={data?.sanityTeamsWeAreAt?.brands}
        />
      </div>
      <GlobalMap
        title={getTitle(data?.sanityTeamsDeliveringCountries)}
        subtitle={data?.sanityTeamsDeliveringCountries?.title_secondary}
      />
      <CofoundersPreview
        title={getTitle(data?.sanityTeamsCoFounders)}
        founders={data?.sanityTeamsCoFounders?.co_founders}
      />
      <TeamMemberSlider
        title={getTitle(data?.sanityTeamsMembers)}
        isDark={false}
        caseStudies={formatServiceExperts(data?.sanityTeamsMembers?.members)}
        // filterData={formatExpertFilter(data?.sanityTeamsMembers?.members)}
        filterData={filterData}
      />
      <Riser
        title={data?.sanityTeamsRiser?.title}
        textcontent={data?.sanityTeamsRiser?.description}
        actionButton={data?.sanityTeamsRiser?.button}
      />
    </Layout>
  );
};
export default TeamsPage;

export const query = graphql`
  query {
    sanityTeamsPageMetadata {
      teams_page_metadata {
        metadata {
          metaContent
          metaName
        }
        canonical
        pageMetaDescription
        pageMetaName
      }
    }
    sanityTeamsBanner {
      background_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
      addresses {
        address
        address_short_text
        direction
      }
      title
    }

    sanityTeamsFeatured {
      title
      teams_featured {
        title
        image {
          asset {
            gatsbyImageData(width: 500, placeholder: BLURRED)
          }
        }
      }
    }

    sanityTeamsSummary {
      id
      title {
        _key
        _type
        title_solid
        title_outlined
        is_outline_first
      }
      _rawDescription
      image {
        background_image_1 {
          asset {
            gatsbyImageData(width: 500, placeholder: BLURRED)
          }
        }
        background_image_2 {
          asset {
            gatsbyImageData(width: 500, placeholder: BLURRED)
          }
        }
        focus_image {
          asset {
            gatsbyImageData(width: 500, placeholder: BLURRED)
          }
        }
      }
    }

    sanityTeamsAllStarted {
      id
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      _rawDescription
      banner_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
      notification_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          url
        }
      }
    }

    sanityTeamsDeliveringCountries {
      id
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      title_secondary
    }

    sanityTeamsWeAreAt {
      title {
        title_outlined
        is_outline_first
        title_solid
      }
      brands {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 80)
          }
        }
      }
      global_stuff
      offices
      links
      campaign_views
      awards
      keywords_ranking_position
    }

    sanityTeamsCoFounders {
      id
      title {
        is_outline_first
        title_outlined
        title_solid
      }
      co_founders {
        name
        photo {
          asset {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            title
            description
          }
        }
        position
        description
        social_media {
          youtube
          twitter
          tiktok
          linkedin
          instagram
          facebook
        }
        _rawStory
        should_show_details
      }
    }

    sanityTeamsMembers {
      id
      title {
        is_outline_first
        title_outlined
        title_solid
      }
      members {
        id
        name
        category
        photo {
          asset {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            title
            description
          }
        }
        position
        description
        social_media {
          youtube
          twitter
          tiktok
          linkedin
          instagram
          facebook
        }
        _rawStory
        should_show_details
        is_current_member
      }
    }
    sanityTeamsRiser {
      description
      title
      button {
        url
        title
      }
    }
    RISER_IMAGE: file(relativePath: { eq: "riser-bg.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
