/* eslint-disable no-console */
import React, { ReactNode, useState } from 'react';
import { HeaderText, Image } from '@/components/_root';
import * as styles from './style.module.css';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import CarouselOrGrid from '@/components/carousel-or-grid';

interface Props {
  children: ReactNode;
}

interface HighlightProps {
  highlight: any;
  colors: any;
}

interface BrandItemProps {
  brandLogoUrl: {
    id: number;
    name: string;
    logo: string | IGatsbyImageData;
  };
}

export const HeaderTextContainer = ({ children }: Props): JSX.Element => (
  <div className="text-center pb-12 md:pb-20">{children}</div>
);

export const HighlightsWrapper = ({ children }: Props): JSX.Element => (
  <div className="flex justify-center xs:pb-0 sm:pb-8 md:pb-20">
    <div className={styles.hightlightsWrapper}>{children}</div>
  </div>
);

export const HighlightsItem = ({ highlight, colors }: HighlightProps): JSX.Element => {
  const [countUp, setCountUp] = useState<boolean>(false);
  const highlightAmount = highlight.amount.replace(/\D/g, '');
  const restOfText = highlight.amount.replace(/\d/g, '');

  return (
    <div key={highlight.id} className="flex flex-col text-center -pb-10">
      <div
        style={{
          background: `-webkit-linear-gradient(left,${colors.join()})`,
          WebkitTextFillColor: 'transparent',
          WebkitBackgroundClip: 'text',
        }}
      >
        <HeaderText size="h1" className={styles.header}>
          <VisibilitySensor partialVisibility>
            {({ isVisible }): JSX.Element => (
              <div className={`${styles.textHeight}`}>
                {isVisible && (
                  <CountUp
                    start={countUp ? highlightAmount : 0}
                    duration={countUp ? 0 : 1}
                    end={highlightAmount}
                    suffix={restOfText}
                    onEnd={(): void => setCountUp(true)}
                  />
                )}
              </div>
            )}
          </VisibilitySensor>
        </HeaderText>
      </div>
      <p className="pt-3 text-center">{highlight.name}</p>
    </div>
  );
};
// export const BrandWrapper = ({ children }: Props): JSX.Element => (
//   <div className="flex md:justify-center pt-8 md:pt-0">
//     <div className="overflow-hidden flex flex-row gap-x-4 md:grid md:items-center md:grid-cols-5 md:gap-x-8 md:gap-y-8">
//       {children}
//     </div>
//   </div>
// );

export const BrandWrapper = ({ children }: Props): JSX.Element => {
  const SHOW_ITEMS = {
    sm: 2,
    md: 2,
    lg: 2,
    xl: 3,
  };
  return (
    <div className="flex md:justify-center pt-12 md:pt-0">
      <CarouselOrGrid showItems={SHOW_ITEMS} gridItemCount={5}>
        {children}
      </CarouselOrGrid>
    </div>
  );
};

export const BrandItem = ({ brandLogoUrl }: BrandItemProps): JSX.Element => (
  <Image
    key={brandLogoUrl.id}
    // className="w-20 md:w-28 lg:w-32 h-10 md:h-12 object-contain"
    src={brandLogoUrl}
    alt={brandLogoUrl.name}
    objectFit="contain"
  />
);
