import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ContentWrapper = ({ children }: Props): JSX.Element => {
  return <div className="relative z-30 flex flex-col items-center justify-center">{children}</div>;
};

export const HeaderTextWrapper = ({ children }: Props): JSX.Element => {
  return (
    <div className="md:w-6/12 xl:w-5/12 md:mx-auto text-center py-36 md:py-48">{children}</div>
  );
};
