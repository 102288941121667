import React, { ReactNode } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import BlockContent from '@/components/block-content';
import { Image } from '@/components/_root';
interface Props {
  children: ReactNode;
}

interface DescriptionProps {
  description: ReactNode;
}

interface ImageProps {
  imageUrl: IGatsbyImageData | any;
  notificationUrl: IGatsbyImageData | any;
}

export const FlexWrapper = ({ children }: Props): JSX.Element => {
  return <div className="flex flex-col md:flex-row">{children} </div>;
};

export const TextContentWrapper = ({ children }: Props): JSX.Element => {
  return (
    <div className="w-full md:w-1/2 flex flex-col justify-center py-10 md:py-20 lg:py-32 md:pr-10">
      {children}
    </div>
  );
};

export const HeaderWrapper = ({ children }: Props): JSX.Element => {
  return <div className="flex flex-col">{children}</div>;
};

export const Description = ({ description }: DescriptionProps): JSX.Element => {
  if (typeof description !== 'string')
    return (
      <div className="pt-6 text-white flex flex-col space-y-4">
        <BlockContent blocks={description} />
      </div>
    );
  return <div className="text-white my-6 flex flex-col gap-6">{description}</div>;
};

export const ImageWrapper = ({ children }: Props): JSX.Element => {
  return <div className="w-full md:w-1/2 flex justify-center relative">{children}</div>;
};

export const ImageArea = ({ imageUrl, notificationUrl }: ImageProps): JSX.Element => {
  return (
    <>
      <div className={'relative md:absolute bottom-0'}>
        <Image src={imageUrl} alt="" className="overflow-hidden md:w-11/12 bottom-0 lg:-mt-4" />
      </div>
      <div className="rounded-md absolute bottom-0 z-30 mb-8 md:-mb-8 lg:left-auto right-0 lg:-right-4 xl:-right-8">
        <Image src={notificationUrl} alt="" />
      </div>
    </>
  );
};

export const ShadowArea = (): JSX.Element => (
  <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-dominant opacity-75" />
);
