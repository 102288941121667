import React from 'react';
import { Container, PageSection, SwiperItem } from '../_root';
import {
  BrandItem,
  BrandWrapper,
  HeaderTextContainer,
  HighlightsItem,
  HighlightsWrapper,
} from './styled';
import getGradientSteps from '@/utils/helper/getGradientSteps';
import SectionTitle from '../section-title';

interface Props {
  title: { outlined?: string; solid?: string };
  highlights: {
    global_stuff: string;
    offices: string;
    links: string;
    campaign_views: string;
    awards: string;
    keywords_ranking_position: string;
  };
  brandLogoUrls: any;
}

interface HighlightItemProps {
  index: number;
  highlight: {
    name: string;
    amount: string;
  };
}

const colors: string[] = getGradientSteps(['#9542F6', '#EA36A8', '#F09A52'], 5);

const TeamsHighlights = ({ title, highlights, brandLogoUrls }: Props): JSX.Element => {
  const hightLightData = {
    'Global Staff': highlights.global_stuff,
    Awards: highlights.awards,
    Offices: highlights.offices,
    'Campaign Views': highlights.campaign_views,
    Links: highlights.links,
    'Keywords ranking position 1-3': highlights.keywords_ranking_position,
  };

  return (
    <PageSection paddingAmount="large" mode="light">
      <Container>
        <HeaderTextContainer>
          <SectionTitle title={title} isInline />
        </HeaderTextContainer>
        <HighlightsWrapper>
          {Object.keys(hightLightData).map((highlight, index) => (
            <TeamsHighlights.Item
              key={index}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              highlight={{ name: highlight, amount: hightLightData[highlight] }}
              index={index}
            />
          ))}
        </HighlightsWrapper>
        <BrandWrapper>
          {brandLogoUrls.map((brandLogoUrl: any, index: number) => (
            <SwiperItem key={index}>
              <div className={`flex w-full h-20 max-h-20 justify-center items-center`}>
                <BrandItem brandLogoUrl={brandLogoUrl.image ?? brandLogoUrl} />
              </div>
            </SwiperItem>
          ))}
        </BrandWrapper>
      </Container>
    </PageSection>
  );
};

TeamsHighlights.Item = ({ highlight, index }: HighlightItemProps): JSX.Element => {
  return (
    <HighlightsItem
      highlight={highlight}
      colors={
        index % 3 === 0
          ? [colors[0], colors[1], colors[3]]
          : index % 3 === 1
          ? [colors[3], colors[3]]
          : [colors[3], colors[4]]
      }
    />
  );
};

export default TeamsHighlights;
