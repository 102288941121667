import { ICaseStudy } from '@/utils/types';

const formatServiceExperts = (data: any): ICaseStudy[] => {
  return data?.map((expert: any) => {
    return {
      brandName: expert?.name,
      title: expert?.name,
      lgBannerLink: expert?.photo,
      thumbLink: expert?.photo,
      summary: expert?.description,
      caseStudyUrl: expert?.case_study_url,
      subtitle: expert?.position,
      category: expert?.category,
      should_show_details: expert?.should_show_details,
      is_current_member: expert?.is_current_member,
    };
  });
};

export default formatServiceExperts;
