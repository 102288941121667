import React from 'react';
import { HeaderText, Image } from '@/components/_root';
import { ContentWrapper, HeaderTextWrapper } from './styled';
import { StaticImage } from 'gatsby-plugin-image';
import SectionTitle from '../section-title';

interface Props {
  title: {
    solid?: string;
    outlined?: string;
  };
  subtitle: string;
  mapImage?: string;
}

const GlobalMap = ({ title, subtitle }: Props): JSX.Element => {
  return (
    <div className="bg-no-repeat w-full bg-center relative">
      <StaticImage
        src="../../assets/img/map.png"
        alt=""
        className="w-full h-full z-10 top-0 left-0 bg-center object-cover"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />

      <div className="absolute z-20 top-0 left-0 w-full h-full opacity-60">
        <StaticImage
          src="../../assets/img/our-values-overlay.png"
          alt=""
          className="w-full h-full z-10 top-0 left-0 bg-center object-cover"
        />
      </div>
      <ContentWrapper>
        <HeaderText mode="dark" size="h3" className="absolute top-20 text-center hidden lg:block">
          {subtitle}
        </HeaderText>
        <HeaderTextWrapper>
          <SectionTitle title={title} mode="dark" />
        </HeaderTextWrapper>
      </ContentWrapper>
    </div>
  );
};

export default GlobalMap;
