import React, { ReactNode, useMemo } from 'react';
import { HeaderText, Image } from '@/components/_root';
import { getCarouselItemDimension } from '@/utils/carousel';
import { useViewport } from '@/components/_hook';

interface Props {
  children: ReactNode;
}

interface FounderItemProps {
  className?: string;
  bannerLink?: string;
  bannerAlt?: string;
  subHeading?: string;
  heading?: string;
  description?: string;
}
export const HeaderWrapper = ({ children }: Props): JSX.Element => (
  <div className="pb-12 md:pb-16">{children}</div>
);

export const FounderWrapper = ({ children }: Props): JSX.Element => (
  <div className="grid grid-flow-col md:flex space-x-4 md:space-x-12 overflow-hidden">
    {children}
  </div>
);

const CAROUSEL_ITEM_MIN_HEIGHT = 350;
const CAROUSEL_ITEM_MAX_HEIGHT = 525;
const DESCRIPTION_CHAR_LIMIT = 100;

export const FounderItem = ({
  className = '',
  bannerLink,
  bannerAlt,
  subHeading,
  heading,
  description,
}: FounderItemProps): JSX.Element => {
  const { container } = useViewport();
  const itemHeight = useMemo(
    () => getCarouselItemDimension(CAROUSEL_ITEM_MIN_HEIGHT, CAROUSEL_ITEM_MAX_HEIGHT, container),
    [container],
  );
  return (
    <>
      <Image
        src={bannerLink}
        alt={bannerAlt}
        className={`rounded object-cover mb-12  md:w-full overflow-hidden`}
        style={{ height: itemHeight }}
      />
      <HeaderText size="h4" mode="dark" className="truncate">
        {subHeading}
      </HeaderText>
      <div
        style={{
          backgroundImage: `linear-gradient(260deg, #EA36A8  0%, #9542F6  100%)`,
          WebkitTextFillColor: 'transparent',
          WebkitBackgroundClip: 'text',
        }}
        className=" inline-block pt-2 md:pt-4 pb-2 md:pb-4"
      >
        <HeaderText size="h2" mode="dark">
          {heading}
        </HeaderText>
      </div>
      {container === 'sm' ? (
        <p className={`text-white normal-case`}>
          {description && description.length > DESCRIPTION_CHAR_LIMIT
            ? `${description.substring(0, DESCRIPTION_CHAR_LIMIT)} ...`
            : description}
        </p>
      ) : (
        <p className={`text-white text normal-case`}>
          {description && description.length > 300
            ? `${description.substring(0, 300)} ...`
            : description}
        </p>
      )}
    </>
  );
};
