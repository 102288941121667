import React, { ReactNode } from 'react';
import { Container } from '@/components/_root';
import {
  Description,
  FlexWrapper,
  HeaderWrapper,
  ImageArea,
  ImageWrapper,
  ShadowArea,
  TextContentWrapper,
} from './styled';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import SectionTitle from '@/components/section-title';

interface Props {
  title: { outlined: string; solid: string; isOutlinedFirst?: boolean };
  description: ReactNode;
  imageUrl: IGatsbyImageData | any;
  notificationUrl: IGatsbyImageData | any;
}

const AllStartedSection = ({
  title,
  description,
  imageUrl,
  notificationUrl,
}: Props): JSX.Element => {
  return (
    <div className="bg-dominant md:mt-20">
      <Container>
        <FlexWrapper>
          <TextContentWrapper>
            <HeaderWrapper>
              <SectionTitle title={title} mode="dark" />
            </HeaderWrapper>
            <Description description={description} />
          </TextContentWrapper>
          <ImageWrapper>
            <ImageArea imageUrl={imageUrl} notificationUrl={notificationUrl} />
            <ShadowArea />
          </ImageWrapper>
        </FlexWrapper>
      </Container>
    </div>
  );
};

export default AllStartedSection;
