import React, { ReactNode } from 'react';
import IconMap from '@/components/_icons/map-icon';
import { HeaderText, Image } from '@/components/_root';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  children: ReactNode;
}

interface OverlayProps extends Props {
  backgroundImage?: string | IGatsbyImageData;
  isOverlay?: boolean;
}

interface IAddress {
  address: string;
  address_short_text: string;
  direction: string;
}
interface HeaderProps extends Props {
  isAddress?: boolean;
  headerTextWidth?: string;
}

interface AddressProps {
  address: IAddress;
}

export const OverlayContainer = ({
  children,
  backgroundImage,
  isOverlay,
}: OverlayProps): JSX.Element => {
  return (
    <div className="bg-no-repeat w-full relative min-h-120 md:min-h-162">
      <Image
        src={backgroundImage}
        alt=""
        className="top-0 w-full object-cover"
        style={{ position: 'absolute', height: '100%' }}
      />
      {isOverlay && <div className="bg-black absolute h-full w-full top-0 opacity-50" />}
      <div className="relative z-10 py-10">{children}</div>
    </div>
  );
};

export const HeaderWrapper = ({
  isAddress,
  headerTextWidth,
  children,
}: HeaderProps): JSX.Element => {
  const headerClass = ` ${
    headerTextWidth ? headerTextWidth : 'md:w-9/12'
  } pt-32 md:pt-40 lg:pt-52 ${isAddress ? ' pb-20' : ' pb-36'}`;
  return <div className={headerClass}>{children}</div>;
};

export const AddressWrapper = ({ children }: Props): JSX.Element => {
  return <div className="hidden md:flex pb-12">{children}</div>;
};

export const AddressItem = ({
  address: { address, address_short_text, direction },
}: AddressProps): JSX.Element => {
  return (
    <div className="mr-10 cursor-pointer" title={address}>
      <a href={direction} className="flex" target="_blank" rel="noreferrer">
        <div className="pr-2.5">
          <IconMap />
        </div>
        <HeaderText size="h3" mode="dark">
          {address_short_text}
        </HeaderText>
      </a>
    </div>
  );
};
